import React, { useRef } from 'react';
import Slider from 'react-slick';
import HomeFade from './homeFadeIn';
import { useStaticQuery, graphql, Link } from 'gatsby';
import arrow from '../images/whiteArrow.png';
import '../styles/slider/slider.scss';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import videoPlaceholder from '../images/video-placeholder.png';

const HomeSlider = () => {
    const data = useStaticQuery(graphql`
        query HomeSliderQuery {
            wpPage(slug: { eq: "home" }) {
                id
                slug
                homeSlider {
                    homepageSlider {
                        link {
                            url
                        }
                        artist
                        artworkTitle
                        backgroundImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 82, formats: [WEBP])
                                }
                            }
                        }
                        mobileBackgroundImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 82, formats: [WEBP])
                                }
                            }
                        }
                        video {
                            link
                            sourceUrl
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `);

    const item = data.wpPage.homeSlider;

    const sliderRef = useRef();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        swipe: false,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false
    };

    const gotoNext = () => {
        sliderRef.current.slickNext();
    };

    return (
        <main className="homeSlider">
            <Slider {...settings} ref={sliderRef}>
                {item.homepageSlider.map((slide) => (
                    <div className="slider" key="slide.artworkTitle">
                        {/* Video */}
                        {slide.video && (
                            <div className="slider__video">
                                {!slide.backgroundImage && (
                                    <video
                                        width="100%"
                                        height="100%"
                                        playsInline
                                        autoPlay
                                        muted
                                        loop
                                        poster={videoPlaceholder}>
                                        <source src={slide.video.mediaItemUrl} type="video/mp4" />
                                        <track default kind="captions" srcLang="en" />
                                    </video>
                                )}

                                {slide.backgroundImage && (
                                    <video
                                        width="100%"
                                        height="100%"
                                        playsInline
                                        autoPlay
                                        muted
                                        loop
                                        poster={
                                            slide.backgroundImage.localFile.childImageSharp.gatsbyImageData.images
                                                .fallback.src
                                        }>
                                        <source src={slide.video.mediaItemUrl} type="video/mp4" />
                                        <track default kind="captions" srcLang="en" />
                                    </video>
                                )}
                            </div>
                        )}

                        {/* Desktop & Mobile Image */}
                        {slide.backgroundImage && !slide.video && (
                            <figure className="slider__imgDesktop">
                                {/* Desktop with no mobile image */}
                                {!slide.mobileBackgroundImage && (
                                    <GatsbyImage
                                        image={slide.backgroundImage.localFile.childImageSharp.gatsbyImageData}
                                        alt="Artwork"
                                    />
                                )}

                                {/* Desktop and mobile image */}
                                {slide.mobileBackgroundImage && (
                                    <figure>
                                        <GatsbyImage
                                            image={slide.backgroundImage.localFile.childImageSharp.gatsbyImageData}
                                            alt="Artwork"
                                            className="slider__imgDesktopActive"
                                        />
                                        <GatsbyImage
                                            image={
                                                slide.mobileBackgroundImage.localFile.childImageSharp.gatsbyImageData
                                            }
                                            alt="slider"
                                            srcSet={slide.mobileBackgroundImage.srcSet}
                                            className="slider__mobileImg"
                                        />
                                    </figure>
                                )}
                            </figure>
                        )}

                        <div className="slider__text-container">
                            {!slide.link && (
                                <HomeFade>
                                    <div className="slider__line"></div>

                                    <Link to={'/projects'} className="slider-link">
                                        <div className="slider__textBox">
                                            <p className="slider__text-container--header font__homeHeader">
                                                {slide.artist}
                                            </p>
                                            <p className="slider__text-container--text font__slider-sub">
                                                {slide.artworkTitle}
                                            </p>
                                        </div>
                                        <div className="slider__more">
                                            <p className="slider__moreText font__sans">Find out more</p>
                                            <img src={arrow} alt="arrow" />
                                        </div>
                                    </Link>
                                </HomeFade>
                            )}

                            {slide.link && (
                                <HomeFade>
                                    <div className="slider__line"></div>

                                    <Link to={'/projects' + slide.link.url} className="slider-link">
                                        <div className="slider__textBox">
                                            <p className="slider__text-container--header font__homeHeader">
                                                {slide.artist}
                                            </p>
                                            <p className="slider__text-container--text font__slider-sub">
                                                {slide.artworkTitle}
                                            </p>
                                        </div>
                                        <div className="slider__more">
                                            <p className="slider__moreText font__sans">Find out more</p>
                                            <img src={arrow} alt="arrow" />
                                        </div>
                                    </Link>
                                </HomeFade>
                            )}
                        </div>
                        <div
                            onClick={() => gotoNext()}
                            className="slider__nextBtn"
                            onKeyDown={() => gotoNext()}
                            role="button"
                            aria-label="Next Slide"
                            tabIndex={0}></div>
                    </div>
                ))}
            </Slider>
        </main>
    );
};

export default HomeSlider;
