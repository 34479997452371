import * as React from 'react';
import LayoutHome from '../components/layout-home';
import Slider from '../components/Slider';

// markup
const IndexPage = () => {
    return (
        <LayoutHome>
            <Slider />
        </LayoutHome>
    );
};

export default IndexPage;
