import React from 'react';
import Headerhome from './home-header';
import { Helmet } from 'react-helmet';
import '../styles/base.scss';
import '../styles/typography.scss';
import '../styles/header.scss';
import { motion } from 'framer-motion';

const LayoutHome = ({ children }) => {
    return (
        <motion.div
            className="layout"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                type: 'spring',
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}
        >
            <Helmet>
                <meta name="title" content="Art Source" data-react-helmet="true" />
                <title>Art Source</title>
                <meta name="description" content="art source. Innovative installation of contemporary art" />
                <link
                    rel="preload"
                    as="style"
                    href="https://use.typekit.net/mtv5rui.css"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
                <link
                    rel="preconnect"
                    href="https://fonts.googleapis.com"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin defer />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap" />
                <link
                    rel="preload"
                    as="style"
                    type="text/css"
                    charset="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
                <link
                    rel="preload"
                    as="style"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    onload="this.onload=null;this.rel='stylesheet'"
                />
            </Helmet>
            <Headerhome />
            {children}
        </motion.div>
    );
};

export default LayoutHome;
