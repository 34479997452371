import React, { useEffect } from 'react';
import Logo from '../images/Art_Source_White_logo.svg';
import MobileNav from './mobileNav';
import { Link } from 'gatsby';
import { useState } from 'react';
import './../styles/menuButton.scss';
import { motion } from 'framer-motion';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

// Animations

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            type: 'tween',
            when: 'beforeChildren',
            staggerChildren: 0.4
        }
    }
};

const childVariants = {
    hidden: {
        y: '-15px',
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1
    }
};

const Homeheader = () => {
    useEffect(() => {
        bodyScrollLock.clearAllBodyScrollLocks();
    }, []);

    const [menu, setMenu] = useState(false);
    const [menuClass, setMenuClass] = useState('not-open');

    const handleClick = () => {
        const body = document.body;

        if (menu === false) {
            setMenu(true);
            setMenuClass('open');
            disableBodyScroll(body);
        } else {
            setMenu(false);
            setMenuClass('not-open');
            enableBodyScroll(body);
        }
    };

    return (
        <header className="white-header">
            <div className="site-wrap">
                <div className="main-logo__container">
                    <Link to="/" className="main-logo">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
                <div className="desktopNav">
                    <nav>
                        <motion.div className="links" variants={containerVariants} initial="hidden" animate="visible">
                            <Link to="/projects" activeClassName="activeLink">
                                <motion.p
                                    className="font__mid"
                                    variants={childVariants}
                                    transition={{ type: 'tween', duration: 0.4 }}
                                >
                                    Projects
                                </motion.p>
                            </Link>
                            <Link to="/about" activeClassName="activeLink">
                                <motion.p
                                    className="font__mid"
                                    variants={childVariants}
                                    transition={{ type: 'tween', duration: 0.4 }}
                                >
                                    About
                                </motion.p>
                            </Link>
                            <Link to="/contact" activeClassName="activeLink">
                                <motion.p
                                    className="font__mid"
                                    variants={childVariants}
                                    transition={{ type: 'tween', duration: 0.4 }}
                                >
                                    Contact
                                </motion.p>
                            </Link>
                        </motion.div>
                    </nav>
                </div>
                {menu && <MobileNav />}

                <div
                    className={'menuButton ' + menuClass}
                    onClick={handleClick}
                    onKeyDown={handleClick}
                    role="button"
                    tabIndex={0}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default Homeheader;
