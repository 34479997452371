import React from 'react';
import { motion } from 'framer-motion';

// Fade in on scroll
function HomeFade({ children }) {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 1.3 }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: '15px' }
            }}
        >
            {children}
        </motion.div>
    );
}

export default HomeFade;
